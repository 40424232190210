<template>
<div class="container" style="max-width: 600px;">
  <br><br>

  {{ $store.state.chat }}
  <div style="height: 70vh; overflow-y:auto;overflow-x: hidden;margin-bottom:20px;display:flex;align-items: flex-end; box-sizing: border-box;flex-direction: column-reverse;">
    <div style="display: block; position: relative; width:100%; box-sizing: border-box;">
        <div class="row" v-for="text in messages">
            <div v-show="!text.medicine">
                <div v-show="text.sender=='bot'" class="col animate__animated animate__slideInLeft animate__faster">
                    <p style="background-color: #d5d5d5;border-color: #d3d6d8;padding:10px; border-radius: 7px 7px 7px 0px; display: inline-block;" v-html="text.msg"></p>
                </div>
                <div v-show="text.sender=='user'" class="col text-end animate__animated animate__slideInRight animate__faster">
                    <p style="background-color: #d5d5d5;border-color: #d3d6d8;padding:10px; border-radius: 7px 7px 0px 7px; display: inline-block;" v-html="text.msg"></p>
                </div>
            </div>
            <div v-show="text.medicine">
                <div v-for="medicine in text.msg" style="width:50%" class="alert alert-primary">
                    {{medicine}}
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div v-for="option, index in options" class="col text-center">
      <button type="button" v-if="index<5" v-bind:class="'animate__animated animate__fadeInUp btn btn-light btn'+index" @click="send(option.value)" >{{option.value}}</button>
      <br><br>
    </div>
  </div>
  <div id="inputrow" class="row animate__animated">
      <div class="col">
          <div class="input-group mb-3">
              <input id="input" type="text" class="form-control" placeholder="Digita Qui" v-on:keyup.enter="send()">
              <!-- <button class="btn btn-outline-secondary" type="button" @click="uploadTrigger()"><i class="bi bi-paperclip"></i></button> -->
              <button class="btn btn-outline-secondary" type="button" @click="send()">Invia</button>
              <input @change="upload($event)" id="fileupload" type="file" accept="image/jpeg, image/png, image/jpg" style="display: none" />
          </div>
      </div>
  </div>

</div>
      

</template>

<style>
  .col{
    transition: 0.5s ease;
  }
  .btn0{
    background-color: #6fa9ff!important;
  }
  .btn1{
    background-color: #4eb786!important;
  }
  .btn2{
    background-color: #f57682!important;
  }
  .btn3{
    background-color: #f5d36f!important;
  }
  .btn4{
    background-color: #0dcaf0!important;
  }
</style>

<script>

import axios from "axios"
import natural from "natural"
import { NeuralNetwork } from '@nlpjs/neural'


export default {
  
  name: 'App',
  mounted(){
    axios.get(".netlify/functions/get-intents").then(res=>{
      this.intents = res.data
      this.messages.push({
        msg:"Ciao, come posso esserti utile?",
        sender: "bot"
      })
    })
    this.ask()
  },
  data(){
    return{
      messages: [],
      intents: [],
      sessionData: {
        lastOptions: []
      },
      options: []
    }
  },
  methods:{
    getmsg: function(){
      let self = this
      let cache = JSON.parse(JSON.stringify(this.messages)).length
      return new Promise((resolve,reject)=>{
        function check(){
          setTimeout(() => {
            if(cache != self.messages.length){
              if(self.messages[self.messages.length-1].sender == "user"){
                resolve(self.messages[self.messages.length-1].msg)
              }
            }
            check()
          }, 1000);
        }
        check()
      })
    },
    send: function(input){           
      if(input){
        let newdoc = {
            msg: input,
            sender: "user"
        }
        this.messages.push(newdoc)
      }else{
        if(document.getElementById("input").value != ""){
            let newdoc = {
                msg: document.getElementById("input").value,
                sender: "user"
            }
            this.messages.push(newdoc)
            document.getElementById("input").value = ""
        }
      }
    },
    detectIntent: function(input){

      let self = this
      var tokenizer = new natural.WordTokenizer();

      return new Promise((resolve,reject)=>{
          const net = new NeuralNetwork();

          self.createCorpus(self.intents).then(corpus=>{

              net.train(corpus);
              let tokens = tokenizer.tokenize(input)          //tokenize input

              //create corpus from input
              let transformedInput = []
              tokens.forEach(token=>{
                  transformedInput[natural.PorterStemmerIt.stem(token)]=1
              })

              let result = net.run(transformedInput)                //analyze input

              //transform result
              let objres = []
              Object.keys(result).forEach(key=>{
                let objdoc = {
                  intent: key,
                  probability: result[key]
                }
                objres.push(objdoc)
              })

              //get best match
              let vals = objres.map(a => a.probability)
              let maxval = Math.max(...vals)

              let matchingId = objres.find(item=>item.probability==maxval).intent
              let matchDoc = self.intents.find(item=>item.ref["@ref"].id==matchingId)

              //return best match
              if(maxval>0.6){
                resolve(matchDoc)
              }else{
                /* if(sessionData.lastOptions.length>0){
                  sessionData.lastOptions.forEach((branch,index)=>{
                    branch.forEach((option,optionIndex)=>{
                      if(natural.PorterStemmerIt.stem(input.toLowerCase()).includes(natural.PorterStemmerIt.stem(option))){
                        let oldBranch = sessionData.lastBranch
                        oldBranch[index] = option

                        remediesList = defaultIntents.find(element=>element.id == sessionData.lastId).remedies
                        remediesList.forEach(remedy=>{
                          if(JSON.stringify(remedy.suitableFor.sort())==JSON.stringify(oldBranch.sort())){
                            root.addRes(remedy.items,true)
                            reject("ignore")
                          }
                        })
                      }else{
                        if(optionIndex==branch.length-1 && index == sessionData.lastOptions.length-1){
                          reject("no matches")
                        }
                      }
                    })
                  })
                }else{ */
                  reject("no matches")
                /* } */
              }
          })


      })
    },
    createCorpus: function(input){
      var tokenizer = new natural.WordTokenizer();
      return new Promise((resolve,reject)=>{
        let corpus = []
        try{
            input.forEach((el,index)=>{
                el.data.phrases.forEach(phrase=>{
                    let tokens = tokenizer.tokenize(phrase.value)
                    let doc = {
                        input: {},
                        output: { [el.ref["@ref"].id]: 1 }
                    }
                    tokens.forEach(tok=>{
                        doc.input[natural.PorterStemmerIt.stem(tok)] = 1
                    })
                    corpus.push(doc)
                })
                if(index=input.length){
                    resolve(corpus)
                }
            })
        }catch(err){
            reject(err)
        }
      })
    },
    selectBranches: function(diram){
      let self = this
      var result = Promise.resolve();
      diram.forEach(branch => {
          self.sessionData.lastOptions.push(diram)
          result = result.then((lastRes) => self.selectBranch(branch,lastRes));
      });
      return result;
    },
    selectBranch: function(branch,lastRes){
      let root = this
      var tokenizer = new natural.WordTokenizer();
      return new Promise((resolve,reject)=>{

        root.options = branch.opz

        root.messages.push({
          msg: branch.domanda,
          sender: "bot"
        })

        let soFar = []

        if(lastRes){
            soFar = lastRes
        }

        let branchIntents = [
            { input: { per: 1, favor: 1, cancell: 1 }, output: { cancel: 1 } },
            { input: { cancell: 1, per: 1, favor: 1 }, output: { cancel: 1 } },
            { input: { annullal: 1 }, output: { cancel: 1 } },
            { input: { annull: 1 }, output: { cancel: 1 } },
            { input: { cancell: 1, quell: 1 }, output: { cancel: 1 } },
            { input: { cancellal: 1 }, output: { cancel: 1 } },
            { input: { cancell: 1 }, output: { cancel: 1 } },
            { input: { salt: 1 }, output: { cancel: 1 } },
            { input: { saltal: 1 }, output: { cancel: 1 } },
            { input: { diment: 1 }, output: { cancel: 1 } },
            { input: { dimentical: 1 }, output: { cancel: 1 } },
            { input: { lasc: 1 }, output: { cancel: 1 } },
            { input: { lasc: 1, perd: 1 }, output: { cancel: 1 } },
            { input: { lascial: 1 }, output: { cancel: 1 } },
            { input: { salt: 1 }, output: { cancel: 1 } },
            { input: { ferm: 1 }, output: { cancel: 1 } },
            { input: { no: 1, cancell: 1 }, output: { cancel: 1 } },
            { input: { chiud: 1, la: 1, ricerc: 1 }, output: { cancel: 1 } },
            { input: { cos: 1, intend: 1 }, output: { clarify: 1 } },
            { input: { cos: 1, vuol: 1, dir: 1 }, output: { clarify: 1 } },
            { input: { qual: 1, la: 1, different: 1 }, output: { clarify: 1 } },
            { input: { cos: 1, camb: 1 }, output: { clarify: 1 } },
            { input: { non: 1, lo: 1, so: 1 }, output: { clarify: 1 } },
            { input: { non: 1, ho: 1, ide: 1 }, output: { clarify: 1 } },
            { input: { non: 1, cap: 1 }, output: { clarify: 1 } },
            { input: { non: 1, saprei: 1 }, output: { clarify: 1 } },
            { input: { che: 1, different: 1, c: 1 }, output: { clarify: 1 } }
        ]

        branch.opz.forEach((phrase)=>{
            let tokens = tokenizer.tokenize(phrase.value)
            let doc = {
                input: {},
                output: { [phrase.value]: 1 }
            }
            tokens.forEach(tok=>{
                doc.input[natural.PorterStemmerIt.stem(tok)] = 1
            })
            branchIntents.push(doc)
        })

        const net = new NeuralNetwork();
        net.train(branchIntents);

        function askBranches(){

          root.getmsg().then(input=>{
            let tokens = tokenizer.tokenize(input)        //tokenize input

            //create corpus from input
            let transformedInput = []
            tokens.forEach(token=>{
                transformedInput[natural.PorterStemmerIt.stem(token)]=1
            })

            let result = net.run(transformedInput)                //analyze input

            //transform result
            let objres = []
            Object.keys(result).forEach(key=>{
                let objdoc = {
                    intent: key,
                    probability: result[key]
                }
                objres.push(objdoc)
            })

            //get best match
            let vals = objres.map(a => a.probability)
            let maxval = Math.max(...vals)

            //return best match
            if(maxval>0.7){
                let finalres = objres.find(item=>item.probability == maxval)
                if(branch.opz.find(el => el.value == finalres.intent)){
                    soFar.push(finalres.intent)
                    resolve(soFar)
                }else{
                    if(finalres.intent == "clarify"){
                      root.messages.push({
                        msg: branch.sp,
                        sender: "bot"
                      })
                      askBranches()
                    }
                    if(finalres.intent == "cancel"){
                      root.options = []
                      root.messages.push({
                        msg: "ok",
                        sender: "bot"
                      })
                      reject()
                    }
                }
            }else{
              root.messages.push({
                        msg: "non ho capito, "+branch.domanda,
                        sender: "bot"
                      })
              askBranches()
            }
          })

        }
        askBranches()

      })
    },
    ask: function(){
      let self = this
      this.getmsg().then(res=>{
        self.detectIntent(res).then(ret=>{
          if(ret.data.dirams.length>0){
            document.getElementById("inputrow").classList.add("animate__slideInDown")
            setTimeout(() => {
              document.getElementById("inputrow").classList.remove("animate__slideInDown")
            }, 1000);
            self.selectBranches(ret.data.dirams).then(branchSelection=>{
              ret.data.rems.forEach(el=>{
                if(el.for.every((element) => branchSelection.indexOf(element) !== -1)){
                  self.options = []
                  self.messages.push({
                    msg: el.res,
                    sender: "bot"
                  })
                  document.getElementById("inputrow").classList.add("animate__slideInUp")
                  setTimeout(() => {
                    document.getElementById("inputrow").classList.remove("animate__slideInUp")
                  }, 1000);
                  self.ask()
                }
              })
            })
          }else{
            self.messages.push({
              msg: ret.data.rems[0],
              sender: "bot"
            })
            self.ask()
          }
        }).catch(err=>{
          self.messages.push({
            msg: "Scusa, non ho capito, potresti rifare la domanda?",
            sender: "bot"
          })
          self.ask()
        })
      })
    }
  }
}

</script>